exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3K-Pd {\n  position: relative;\n  padding: 10px;\n  background-color: #404242;\n  text-align: center;\n}\n._3K-Pd ._21eGE {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  gap: 10px;\n}\n._3K-Pd ._21eGE ._3MVQM {\n  position: relative;\n  display: inline-block;\n  text-align: left;\n  -webkit-box-flex: 1;\n      -ms-flex-positive: 1;\n          flex-grow: 1;\n  padding: 10px;\n}\n._3K-Pd ._21eGE ._3MVQM ._3VLPQ {\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  width: 100%;\n  line-height: 36px;\n  padding-left: 35px;\n  font-size: 14px;\n  border-radius: 10px;\n  border: 0;\n}\n._3K-Pd ._21eGE ._3MVQM ._1SDuQ {\n  position: absolute;\n  top: 18px;\n  left: 18px;\n}\n._3K-Pd ._21eGE ._3p--t {\n  display: inline-block;\n  position: relative;\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  padding: 10px;\n}\n._3K-Pd ._21eGE ._3p--t button {\n  border-radius: 10px;\n}\n._3K-Pd ._21eGE ._1PLnA {\n  display: inline-block;\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  cursor: pointer;\n  padding: 10px;\n  color: #A0A0A0;\n}\n._2FcGx {\n  margin-top: 5px;\n  padding-top: 10px;\n  height: 405px;\n  overflow: auto;\n}\n._2FcGx ul {\n  margin: 0;\n}\n", ""]);

// Exports
exports.locals = {
	"form": "_3K-Pd",
	"wrapper": "_21eGE",
	"search": "_3MVQM",
	"input": "_3VLPQ",
	"icon": "_1SDuQ",
	"btnSearch": "_3p--t",
	"btnAdvancedSearch": "_1PLnA",
	"list": "_2FcGx"
};