import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Map } from 'immutable';
import loc from 'Components/languages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import DropdownMenu from 'Components/dropdown-menu';
import ModalLotNo from '../../sales-order/cart/components/ModalLotNo.jsx';
import ModalQuantity from './ModalQuantity.jsx';
import styles from './TransactionItemRow.less';

class TransactionItemRow extends PureComponent {
	constructor() {
		super();
		this.state = {
			showDropdownMenu: false,
			showModalLotNo: false,
			showModalQuantity: false,
		};
		this.onSelectLotNoHandler = this.onSelectLotNoHandler.bind(this);
		this.onToggleDropdownMenuHandler = this.onToggleDropdownMenuHandler.bind(this);
		this.onToggleModalLotNoHandler = this.onToggleModalLotNoHandler.bind(this);
		this.onToggleModalQuantityHandler = this.onToggleModalQuantityHandler.bind(this);
		this.onClickUpdateHandler = this.onClickUpdateHandler.bind(this);
		this.onClickInsertLotNoHandler = this.onClickInsertLotNoHandler.bind(this);
		this.onClickRemoveHandler = this.onClickRemoveHandler.bind(this);
		this.onChangeQuantityHandler = this.onChangeQuantityHandler.bind(this);
	}

	componentDidMount() {
		const transactionItem = this.props.transactionItem;
		if (transactionItem && !transactionItem.get('lot_no')) {
			this.setState({
				showModalLotNo: true,
			});
		}
	}

	componentDidUpdate(prevProps) {
		const transactionItem = this.props.transactionItem;
		const prevTransactionItem = prevProps.transactionItem;
		if (transactionItem && transactionItem !== prevTransactionItem) {
			if (!transactionItem.get('lot_no')) {
				this.setState({
					showModalLotNo: true,
				});
			}
		}
	}

	onSelectLotNoHandler(lotNo) {
		const { index, onSelectLotNo } = this.props;
		this.setState({
			showModalLotNo: false,
		});
		onSelectLotNo(index, lotNo);
	}

	onToggleDropdownMenuHandler() {
		const { showDropdownMenu } = this.state;
		const { onShowOverlay, onHideOverlay } = this.props;
		this.setState({
			showDropdownMenu: !showDropdownMenu,
		});
		if (showDropdownMenu) {
			onHideOverlay();
		} else {
			onShowOverlay(this.onToggleDropdownMenuHandler);
		}
	}

	onToggleModalLotNoHandler() {
		const { showModalLotNo } = this.state;
		this.setState({
			showModalLotNo: !showModalLotNo,
		});
	}

	onToggleModalQuantityHandler() {
		const { showModalQuantity } = this.state;
		this.setState({
			showModalQuantity: !showModalQuantity,
		});
	}

	onClickUpdateHandler(e) {
		e.preventDefault();
		this.setState({
			showModalQuantity: true,
		}, this.props.onHideOverlay);
	}

	onClickInsertLotNoHandler(e) {
		e.preventDefault();
		this.setState({
			showModalLotNo: true,
		}, this.props.onHideOverlay);
	}

	onClickRemoveHandler(e) {
		const { index, onRemove } = this.props;
		e.preventDefault();
		onRemove(index);
	}

	onChangeQuantityHandler(data) {
		const { index, onChange } = this.props;
		onChange(index, data);
	}

	render() {
		const { index, transactionItem, exceptions, appLanguage, forceSelectLotNo } = this.props;
		const { showDropdownMenu, showModalLotNo, showModalQuantity } = this.state;
		return (
			<li className={ classnames(styles.container, exceptions && exceptions.length > 0 && styles.invalid) }>
				<div className="uk-width-1-1 uk-text-primary uk-text-bold">
					{
						appLanguage.language === 'en' ? (
							transactionItem.get('item_name_en')
						) : (
							transactionItem.get('item_name_tc')
						)
					}
				</div>
				<div className="uk-flex">
					<div className="uk-width-2-5">
						<div>{ transactionItem.get('item_code') }</div>
						<div>{ transactionItem.get('lot_no') }</div>
					</div>
					<div className="uk-width-2-5">
						<span>
							{
								appLanguage.language === 'en' ? (
									transactionItem.get('brand').name_en
								) : (
									transactionItem.get('brand').name_tc
								)
							}
						</span>
					</div>
					<div className="uk-width-1-5 uk-text-capitalize">
						{ loc.qty }: { transactionItem.get('item_quantity') }
					</div>
				</div>
				<div className="uk-padding-small uk-padding-remove-vertical uk-position-top-right uk-position-small">
					<div className={ styles.button } onClick={ this.onToggleDropdownMenuHandler }>
						<FontAwesomeIcon icon={ faEllipsisV } />
					</div>
					<DropdownMenu width="200px" active={ showDropdownMenu } onToggle={ this.onToggleDropdownMenuHandler }>
						<li><a href="#" onClick={ this.onClickUpdateHandler }>{ loc.updateQuantity }</a></li>
						<li><a href="#" onClick={ this.onClickInsertLotNoHandler }>{ loc.insertLotNo }</a></li>
						<li><a href="#" className="uk-text-danger" onClick={ this.onClickRemoveHandler }>{ loc.removeItem }</a></li>
					</DropdownMenu>
				</div>
				{
					exceptions && exceptions.map((exception, j) => (
						<div
							key={ `transaction-item-row-${index}-exception-${j}` }
							className="uk-width-1-1 uk-margin-small-top uk-text-meta uk-text-danger uk-text-bold"
						>
							<FontAwesomeIcon icon={ faExclamationCircle } />&nbsp;
							{ loc[exception.errorCode] }
						</div>
					))
				}

				<ModalLotNo
					isOpen={ showModalLotNo || forceSelectLotNo }
					onToggle={ this.onToggleModalLotNoHandler }
					onSelect={ this.onSelectLotNoHandler }
					itemCode={ transactionItem.get('item_code') }
				/>

				<ModalQuantity
					isOpen={ showModalQuantity }
					onToggle={ this.onToggleModalQuantityHandler }
					onChange={ this.onChangeQuantityHandler }
					transactionItem={ transactionItem }
				/>
			</li>
		);
	}
}

TransactionItemRow.propTypes = {
	index: PropTypes.number.isRequired,
	transactionItem: PropTypes.instanceOf(Map).isRequired,
	exceptions: PropTypes.array,
	forceSelectLotNo: PropTypes.bool.isRequired,
	onShowOverlay: PropTypes.func.isRequired,
	onHideOverlay: PropTypes.func.isRequired,
	onSelectLotNo: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		appLanguage: state.appLanguage,
	})
)(TransactionItemRow);