import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import loc from 'Components/languages';
import styles from './TransferOrderListItem.less';

class TransferOrderListItem extends PureComponent {
	render() {
		const { order, type } = this.props;
		return (
			<li
				className={ classnames(styles.list) }
			>
				<Link to={ `/app/stock-transfer/${type === 'transferOut' ? 'out' : 'in'}/${order.order_type}/${order.transaction_number}` }>
					<div className="uk-card uk-card-body uk-card-small uk-position-relative">
						<div className="uk-flex uk-flex-between uk-text-small uk-text-muted uk-text-capitalize">
							<div className="uk-width-1-4 uk-text-primary uk-h6 uk-margin-remove-bottom">
								{ order.transaction_number }
							</div>
							<div className="uk-width-1-4">
								{ loc.createDate }: { order.created_date ? moment(order.created_date).format('YYYY-MM-DD HH:mm:ss') : '' }
							</div>
							<div className="uk-width-1-4">
								{
									type === 'transferOut' ? (
										`${loc.destination}: ${order.wh_desc_to}`
									) : (
										`${loc.sourceDestination}: ${order.wh_desc_from}`
									)
								}
							</div>
							<div className="uk-width-1-4 uk-position-relative">
								{ loc.qty }: { order.item_count }

								<div className="uk-position-center-right">
									<FontAwesomeIcon icon={ faChevronRight } className="fa-lg" />
								</div>
							</div>
						</div>
						{
							order.remark && (
								<div className="uk-flex uk-flex-between uk-text-small uk-text-muted">
									<div className="uk-width-1-1">
										<div className={ styles.remark }>{ order.remark }</div>
									</div>
								</div>
							)
						}
					</div>
				</Link>
			</li>
		);
	}
}

TransferOrderListItem.propTypes = {
	index: PropTypes.number.isRequired,
	order: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default TransferOrderListItem;