exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._16OOF {\n  text-transform: capitalize;\n  background-color: #F6F4F0;\n  padding: 10px;\n  font-weight: bold;\n  color: #BD9471;\n}\n._3p5sO {\n  display: inline-block;\n  width: 50px;\n  text-align: center;\n}\n._1AP0Q {\n  display: inline-block;\n  width: calc(100% - 50px);\n}\n.GtYeV::-webkit-input-placeholder {\n  text-transform: capitalize;\n}\n.GtYeV::-moz-placeholder {\n  text-transform: capitalize;\n}\n.GtYeV:-ms-input-placeholder {\n  text-transform: capitalize;\n}\n.GtYeV::-ms-input-placeholder {\n  text-transform: capitalize;\n}\n.GtYeV::placeholder {\n  text-transform: capitalize;\n}\n._1adEA {\n  padding-right: 5px;\n}\n._3dmNG {\n  padding-left: 5px;\n}\n", ""]);

// Exports
exports.locals = {
	"label": "_16OOF",
	"unit": "_3p5sO",
	"input": "_1AP0Q",
	"inputQty": "GtYeV",
	"buttonColumnLeft": "_1adEA",
	"buttonColumnRight": "_3dmNG"
};