exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Y9XLU {\n  margin-top: 0 !important;\n  border-top-color: #A0A0A0 !important;\n}\n.Y9XLU {\n  padding-bottom: 10px;\n  padding-left: 20px;\n  padding-right: 20px;\n  cursor: pointer;\n}\n.Y9XLU .Wid5z {\n  display: inline-block;\n  vertical-align: middle;\n  width: calc(100% - 30px);\n}\n.Y9XLU .Wid5z ._6U3AZ {\n  color: #99785C;\n  font-weight: bold;\n  font-size: 18px;\n}\n.Y9XLU .Wid5z ._2shJe {\n  color: #A0A0A0;\n  font-size: 15px;\n}\n.Y9XLU ._3sg-H {\n  display: inline-block;\n  vertical-align: middle;\n  width: 30px;\n  color: #A0A0A0;\n  text-align: center;\n  cursor: pointer;\n}\n.Y9XLU ._3sg-H.qQDGj {\n  color: #99785C;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "Y9XLU",
	"details": "Wid5z",
	"title": "_6U3AZ",
	"desc": "_2shJe",
	"star": "_3sg-H",
	"isSelected": "qQDGj"
};