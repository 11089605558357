exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3R7Cu {\n  background-color: #404242;\n  text-align: center;\n  line-height: 50px;\n  color: #A0A0A0;\n}\n.RZoya {\n  background-color: #404242;\n}\n.RZoya ._2u2Cw {\n  position: relative;\n  text-align: center;\n  padding: 5px 0 10px 15px;\n  display: inline-block;\n  width: calc(100% - 30px);\n}\n.RZoya ._2u2Cw ._2ZxOU {\n  width: calc(100% - 35px);\n  height: 30px;\n  line-height: 36px;\n  padding-left: 35px;\n  font-size: 14px;\n  border-radius: 10px;\n  border: 0;\n}\n.RZoya ._2u2Cw .oa8r- {\n  position: absolute;\n  top: 9px;\n  left: 26px;\n}\n._3U4fC {\n  margin-top: 5px;\n  padding-top: 10px;\n  height: calc(100% - 165px);\n  overflow: auto;\n}\n._3U4fC ul {\n  margin: 0;\n}\n", ""]);

// Exports
exports.locals = {
	"desc": "_3R7Cu",
	"searchContainer": "RZoya",
	"search": "_2u2Cw",
	"input": "_2ZxOU",
	"icon": "oa8r-",
	"list": "_3U4fC"
};